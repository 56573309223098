// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `synchronization-icon {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  position: relative;
}

.synchronization-icon--IconError {
  color: #bd2b2b;
}

.synchronization-icon--Badge {
  display: inline-block;
  position: absolute;

  font-size: 1.1em;
  background: var(--record-it-color-primary);
  color: white;
  border-radius: 0.6em;
  text-align: center;
  line-height: 1.2em;
  box-sizing: content-box;

  min-width: 0.75em;
  padding: 0.1em 0.28em;
}

.synchronization-icon--BadgeBottom {
  bottom: -7px;
  right: -7px;
}

.synchronization-icon--BadgeTop {
  top: -7px;
  right: -7px;
}

.synchronization-icon--BadgeFailed {
  background: var(--record-it-color-red);
}
`, "",{"version":3,"sources":["webpack://./src/synchronizationComponents/synchronization-icon/synchronization-icon.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;;EAElB,gBAAgB;EAChB,0CAA0C;EAC1C,YAAY;EACZ,oBAAoB;EACpB,kBAAkB;EAClB,kBAAkB;EAClB,uBAAuB;;EAEvB,iBAAiB;EACjB,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,SAAS;EACT,WAAW;AACb;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":["synchronization-icon {\n  display: inline-block;\n  text-align: center;\n  vertical-align: middle;\n  position: relative;\n}\n\n.synchronization-icon--IconError {\n  color: #bd2b2b;\n}\n\n.synchronization-icon--Badge {\n  display: inline-block;\n  position: absolute;\n\n  font-size: 1.1em;\n  background: var(--record-it-color-primary);\n  color: white;\n  border-radius: 0.6em;\n  text-align: center;\n  line-height: 1.2em;\n  box-sizing: content-box;\n\n  min-width: 0.75em;\n  padding: 0.1em 0.28em;\n}\n\n.synchronization-icon--BadgeBottom {\n  bottom: -7px;\n  right: -7px;\n}\n\n.synchronization-icon--BadgeTop {\n  top: -7px;\n  right: -7px;\n}\n\n.synchronization-icon--BadgeFailed {\n  background: var(--record-it-color-red);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
