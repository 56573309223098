// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-revision-comparison-full-screen-dialog {
  display: none;
}

.picture-revision-comparison-full-screen-dialog--TopBarContainer {
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
  gap: 8px 20px;
  align-items: center;
}

.picture-revision-comparison-full-screen-dialog--ToolButton {
  width: 250px;
  border: 0;
  padding: 6px 12px;
  background: var(--record-it-color-gray-600);
  color: white;
  text-align: center;
  text-overflow: clip;
}

.picture-revision-comparison-full-screen-dialog--ToolButton:disabled {
  background: var(--record-it-color-gray-500);
  pointer-events: none;
}

.picture-revision-comparison-full-screen-dialog--ActiveToolButton {
  background: var(--record-it-color-primary);
}

.picture-revision-comparison-full-screen-dialog--MainContent {
  display: flex;
  gap: 10px;
  padding: 10px 10px;
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--record-it-color-gray-400);
}

.picture-revision-comparison-full-screen-dialog--ComparisonPicture {
  flex: 1 1 100%;
  min-width: 0;
}

@media (max-width: 767px) {
  .picture-revision-comparison-full-screen-dialog--MainContent {
    flex-direction: column;
    width: calc(100% - 30px);
  }
}
`, "",{"version":3,"sources":["webpack://./src/pictureRevisionComponents/picture-revision-comparison-full-screen-dialog/picture-revision-comparison-full-screen-dialog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,cAAc;EACd,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,2CAA2C;EAC3C,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,2CAA2C;EAC3C,oBAAoB;AACtB;;AAEA;EACE,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,SAAS;EACT,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2CAA2C;AAC7C;;AAEA;EACE,cAAc;EACd,YAAY;AACd;;AAEA;EACE;IACE,sBAAsB;IACtB,wBAAwB;EAC1B;AACF","sourcesContent":["picture-revision-comparison-full-screen-dialog {\n  display: none;\n}\n\n.picture-revision-comparison-full-screen-dialog--TopBarContainer {\n  display: flex;\n  flex-flow: row;\n  flex-wrap: wrap;\n  gap: 8px 20px;\n  align-items: center;\n}\n\n.picture-revision-comparison-full-screen-dialog--ToolButton {\n  width: 250px;\n  border: 0;\n  padding: 6px 12px;\n  background: var(--record-it-color-gray-600);\n  color: white;\n  text-align: center;\n  text-overflow: clip;\n}\n\n.picture-revision-comparison-full-screen-dialog--ToolButton:disabled {\n  background: var(--record-it-color-gray-500);\n  pointer-events: none;\n}\n\n.picture-revision-comparison-full-screen-dialog--ActiveToolButton {\n  background: var(--record-it-color-primary);\n}\n\n.picture-revision-comparison-full-screen-dialog--MainContent {\n  display: flex;\n  gap: 10px;\n  padding: 10px 10px;\n  position: relative;\n  width: 100%;\n  height: 100%;\n  background: var(--record-it-color-gray-400);\n}\n\n.picture-revision-comparison-full-screen-dialog--ComparisonPicture {\n  flex: 1 1 100%;\n  min-width: 0;\n}\n\n@media (max-width: 767px) {\n  .picture-revision-comparison-full-screen-dialog--MainContent {\n    flex-direction: column;\n    width: calc(100% - 30px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
