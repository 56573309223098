// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-map-positioning-gps-mode-picture-selection {
  display: block;
}

.picture-map-positioning-gps-mode-picture-selection--PictureSelection {
  display: flex;
  flex: 1 1 auto;

  margin-bottom: 20px;
  overflow-x: auto;
  height: 82px;
}

.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture {
  position: relative;
  display: block;
  width: 84px;
  height: 61px;
  cursor: pointer;
  border: 1px solid rgba(125, 125, 125, 0.25);
}

.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture
  picture-preview {
  width: 100%;
  height: 100%;
  border: none;
}

.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture
  + .picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture {
  margin-left: 10px;
}

.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPictureSelected {
  border: 2px solid rgba(0, 101, 246, 0.49);
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/picture-map-positioning-dialog/picture-map-positioning-gps-mode/picture-map-positioning-gps-mode-picture-selection/picture-map-positioning-gps-mode-picture-selection.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,cAAc;;EAEd,mBAAmB;EACnB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;EACX,YAAY;EACZ,eAAe;EACf,2CAA2C;AAC7C;;AAEA;;EAEE,WAAW;EACX,YAAY;EACZ,YAAY;AACd;;AAEA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["picture-map-positioning-gps-mode-picture-selection {\n  display: block;\n}\n\n.picture-map-positioning-gps-mode-picture-selection--PictureSelection {\n  display: flex;\n  flex: 1 1 auto;\n\n  margin-bottom: 20px;\n  overflow-x: auto;\n  height: 82px;\n}\n\n.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture {\n  position: relative;\n  display: block;\n  width: 84px;\n  height: 61px;\n  cursor: pointer;\n  border: 1px solid rgba(125, 125, 125, 0.25);\n}\n\n.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture\n  picture-preview {\n  width: 100%;\n  height: 100%;\n  border: none;\n}\n\n.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture\n  + .picture-map-positioning-gps-mode-picture-selection--PictureSelectionPicture {\n  margin-left: 10px;\n}\n\n.picture-map-positioning-gps-mode-picture-selection--PictureSelectionPictureSelected {\n  border: 2px solid rgba(0, 101, 246, 0.49);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
