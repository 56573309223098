// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-map-positioning-gps-mode-picture-filter {
  display: block;
}

.picture-map-positioning-gps-mode-picture-filter--TopBar {
  display: flex;
  justify-content: flex-end;
}

.picture-map-positioning-gps-mode-picture-filter--notAllResultsDisplayedHint {
  display: flex;
  align-items: center;
  gap: 5px;
}

.picture-map-positioning-gps-mode-picture-filter--FilterSetWarningTriangle {
  color: var(--record-it-color-orange);
}

.picture-map-positioning-gps-mode-picture-filter--Grid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(3, auto);
}

@media (max-width: 992px) {
  .picture-map-positioning-gps-mode-picture-filter--Grid {
    grid-template-columns: repeat(2, auto);
  }
}

@media (max-width: 767px) {
  .picture-map-positioning-gps-mode-picture-filter--Grid {
    grid-template-columns: minmax(0, 1fr);
  }
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/picture-map-positioning-dialog/picture-map-positioning-gps-mode/picture-map-positioning-gps-mode-picture-filter/picture-map-positioning-gps-mode-picture-filter.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,SAAS;EACT,sCAAsC;AACxC;;AAEA;EACE;IACE,sCAAsC;EACxC;AACF;;AAEA;EACE;IACE,qCAAqC;EACvC;AACF","sourcesContent":["picture-map-positioning-gps-mode-picture-filter {\n  display: block;\n}\n\n.picture-map-positioning-gps-mode-picture-filter--TopBar {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.picture-map-positioning-gps-mode-picture-filter--notAllResultsDisplayedHint {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.picture-map-positioning-gps-mode-picture-filter--FilterSetWarningTriangle {\n  color: var(--record-it-color-orange);\n}\n\n.picture-map-positioning-gps-mode-picture-filter--Grid {\n  display: grid;\n  gap: 20px;\n  grid-template-columns: repeat(3, auto);\n}\n\n@media (max-width: 992px) {\n  .picture-map-positioning-gps-mode-picture-filter--Grid {\n    grid-template-columns: repeat(2, auto);\n  }\n}\n\n@media (max-width: 767px) {\n  .picture-map-positioning-gps-mode-picture-filter--Grid {\n    grid-template-columns: minmax(0, 1fr);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
