// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-map-positioning-gps-mode {
  display: block;
}

.picture-map-positioning-gps-mode--PictureAndCoordinatesContainer {
  display: flex;
  margin-bottom: 30px;
}

.picture-map-positioning-gps-mode--PictureAndCoordinatesLeftContainer {
  flex: 0 0 60%;
}

.picture-map-positioning-gps-mode--PictureAndMarkings {
  width: 100%;
  height: 100%;
}

.picture-map-positioning-gps-mode--PictureAndCoordinatesRightContainer {
  flex: 0 0 40%;
  padding: 20px;
}

.picture-map-positioning-gps-mode--Filter {
  background-color: var(--record-it-color-gray-300);
}

.picture-map-positioning-gps-mode--Header {
  color: var(--record-it-color-gray-800);
  font-size: 16px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.picture-map-positioning-gps-mode--Label {
  color: var(--record-it-color-gray-600);
  margin-bottom: 0.5em;
}

.picture-map-positioning-gps-mode--InfoText {
  color: var(--record-it-color-gray-800);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

@media (max-width: 767px) {
  .picture-map-positioning-gps-mode--PictureAndCoordinatesContainer {
    flex-direction: column;
  }

  .picture-map-positioning-gps-mode--PictureAndCoordinatesLeftContainer {
    flex: 0 1 auto;
  }

  .picture-map-positioning-gps-mode--PictureAndCoordinatesRightContainer {
    flex: 0 1 auto;
    padding: 20px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/dialogs/picture-map-positioning-dialog/picture-map-positioning-gps-mode/picture-map-positioning-gps-mode.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,iDAAiD;AACnD;;AAEA;EACE,sCAAsC;EACtC,eAAe;EACf,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,sCAAsC;EACtC,oBAAoB;AACtB;;AAEA;EACE,sCAAsC;EACtC,kBAAkB;EAClB,qBAAqB;AACvB;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,aAAa;EACf;AACF","sourcesContent":["picture-map-positioning-gps-mode {\n  display: block;\n}\n\n.picture-map-positioning-gps-mode--PictureAndCoordinatesContainer {\n  display: flex;\n  margin-bottom: 30px;\n}\n\n.picture-map-positioning-gps-mode--PictureAndCoordinatesLeftContainer {\n  flex: 0 0 60%;\n}\n\n.picture-map-positioning-gps-mode--PictureAndMarkings {\n  width: 100%;\n  height: 100%;\n}\n\n.picture-map-positioning-gps-mode--PictureAndCoordinatesRightContainer {\n  flex: 0 0 40%;\n  padding: 20px;\n}\n\n.picture-map-positioning-gps-mode--Filter {\n  background-color: var(--record-it-color-gray-300);\n}\n\n.picture-map-positioning-gps-mode--Header {\n  color: var(--record-it-color-gray-800);\n  font-size: 16px;\n  margin-top: 0.5em;\n  margin-bottom: 0.5em;\n}\n\n.picture-map-positioning-gps-mode--Label {\n  color: var(--record-it-color-gray-600);\n  margin-bottom: 0.5em;\n}\n\n.picture-map-positioning-gps-mode--InfoText {\n  color: var(--record-it-color-gray-800);\n  padding-top: 0.5em;\n  padding-bottom: 0.5em;\n}\n\n@media (max-width: 767px) {\n  .picture-map-positioning-gps-mode--PictureAndCoordinatesContainer {\n    flex-direction: column;\n  }\n\n  .picture-map-positioning-gps-mode--PictureAndCoordinatesLeftContainer {\n    flex: 0 1 auto;\n  }\n\n  .picture-map-positioning-gps-mode--PictureAndCoordinatesRightContainer {\n    flex: 0 1 auto;\n    padding: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
