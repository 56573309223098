// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `picture-editor {
  display: block;
}

.picture-editor--CroppingContainer {
  position: relative;
  overflow: hidden;
}

.picture-editor--HasErrorText .picture-editor--CroppingContainer {
  min-height: 100px;
}

.picture-editor--DrawingCanvas {
  position: absolute;

  width: 100%;
  height: 100%;
}

.picture-editor--RepositionableElementBackdrop {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.6);
}

.picture-editor--MainButtonsWrapper {
  margin-top: 15px;
}

.picture-editor--MainButtonsWrapper > * {
  margin-top: 5px;
}

.picture-editor--ErrorTextOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  font-weight: bold;
  color: #dc311e;
}
`, "",{"version":3,"sources":["webpack://./src/aureliaComponents/picture-editor/picture-editor.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;;EAElB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,SAAS;EACT,QAAQ;EACR,oCAAoC;AACtC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":["picture-editor {\n  display: block;\n}\n\n.picture-editor--CroppingContainer {\n  position: relative;\n  overflow: hidden;\n}\n\n.picture-editor--HasErrorText .picture-editor--CroppingContainer {\n  min-height: 100px;\n}\n\n.picture-editor--DrawingCanvas {\n  position: absolute;\n\n  width: 100%;\n  height: 100%;\n}\n\n.picture-editor--RepositionableElementBackdrop {\n  position: absolute;\n  top: 0;\n  left: 0;\n  bottom: 0;\n  right: 0;\n  background: rgba(255, 255, 255, 0.6);\n}\n\n.picture-editor--MainButtonsWrapper {\n  margin-top: 15px;\n}\n\n.picture-editor--MainButtonsWrapper > * {\n  margin-top: 5px;\n}\n\n.picture-editor--ErrorTextOverlay {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  max-width: 100%;\n  font-weight: bold;\n  color: #dc311e;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
