// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `edit_checklist_thing {
  display: block;
}

.edit-checklist-thing--HeaderContent {
  display: flex;
  gap: 0.4em;
  align-items: center;
}

.edit-checklist-thing--SpaceBottom {
  margin-bottom: 6em;
}
`, "",{"version":3,"sources":["webpack://./src/pages/edit_checklist_thing/edit_checklist_thing.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["edit_checklist_thing {\n  display: block;\n}\n\n.edit-checklist-thing--HeaderContent {\n  display: flex;\n  gap: 0.4em;\n  align-items: center;\n}\n\n.edit-checklist-thing--SpaceBottom {\n  margin-bottom: 6em;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
